// import JsBarcode from 'jsbarcode' 
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const returnPdfDetails = async (base64Logo, vm, merchant) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })

    const pdfContent = [
      { image: 'data:image/png;base64,' + base64Logo, height: 70, width: 70, style: 'logo', alignment: 'left' },
      { text: 'Merchant : ' + merchant, style: 'org', alignment: 'left' },
      { text: 'Total Order : ' + vm.length, style: 'org', alignment: 'right' }
    ]

    pdfContent.push({ text: '', style: 'space' })

    const allRows = [
      [
        { text: 'Order ID', style: 'th', alignment: 'center', bold: true },
        { text: 'Deadline', style: 'th', alignment: 'center', bold: true },
        { text: 'Ref ID', style: 'th', alignment: 'center', bold: true },
        { text: 'Customer', style: 'th', alignment: 'center', bold: true },
        { text: 'Price', style: 'th', alignment: 'center', bold: true },
        { text: 'Collected', style: 'th', alignment: 'center', bold: true },
        { text: 'Service Charge', style: 'th', alignment: 'center', bold: true },
        { text: 'Return Charge', style: 'th', alignment: 'center', bold: true },
        { text: 'Weight Charge', style: 'th', alignment: 'center', bold: true },
        { text: 'COD', style: 'th', alignment: 'center', bold: true },
        { text: 'Amount', style: 'th', alignment: 'center', bold: true }
      ]
    ]

    vm.forEach(item => {
      allRows.push([
          { text: item.tracking_id, alignment: 'center', style: 'td' },
          { text: item.date, alignment: 'center', style: 'td' },
          { text: item.ref_id, alignment: 'center', style: 'td' },
          { text: item.name + ', ' + item.mobile, alignment: 'center', style: 'td' },
          { text: item.price, alignment: 'center', style: 'td' },
          { text: item.collected, alignment: 'center', style: 'td' },
          { text: item.service_charge, alignment: 'center', style: 'td' },
          { text: item.return_charge, alignment: 'center', style: 'td' },
          { text: item.weight_charge, alignment: 'center', style: 'td' },
          { text: item.cod, alignment: 'center', style: 'td' },
          { text: item.amount, alignment: 'center', style: 'td' } // fillColor:getCellColor(5)
      ])
    })

    pdfContent.push({
      table: {
        headerRows: 1,
        widths: '*',
        body: allRows
      }
    })

    var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'landscape',
      footer: function (currentPage, pageCount) {
        return {
          table: {
            body: [
                [
                  { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', style: 'normalText', /*margin: [0, 0, 0, 0]*/ }
                ]
            ]
          }
        };
      },
      styles: {
        th: {
          fontSize: 8,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: 8,
          margin: [3, 3, 3, 3]
        },
        space: {
          margin: [5, 0, 0, 5]
        },
        header: {
          fontSize: 17,
          bold: true,
          margin: [0, 5, 0, 0]
        },
        logo: {
          margin: [0, 0, 0, 10]
        },
        normalText: {
          border: 0
        },
        hh: {
          fontSize: 13,
          bold: true,
          margin: [10, 10, 25, 20]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
  } catch (error) {
    if (error) {
      console.log('err => ', error)
    }
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}

// function generateBarcode (orderNo) {
//   let canvas = document.createElement('canvas');
//   JsBarcode(canvas, orderNo, { 
//       format: 'CODE39',
//       displayValue: false,
//       height: 15,
//       width: 1,
//       padding: 0,
//       margin: 0
//   });
//   return canvas.toDataURL('image/png');
// }

export default {
    returnPdfDetails
}
